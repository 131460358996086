<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.documentsOriginRegistration"
          @update:modelValue="update('documentsOriginRegistration', $event)"
          :options="docs"
          @reload="fetchWithDelay"
          :itemValidationRule="itemValidationRule"
          data-qa="documentCoveredChange"
          keyProp="registrationNumber"
        >
          <label class="label">Вносит изменения в документы</label>
        </MultiSelectField>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocSection5 from "../../../models/documents/docSection5";
import DocFields from "./DocFields";
import { toRefs } from "vue";
import useDocIzOrigin from "./hooks/docIzOrigin";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { helpers } from "@vuelidate/validators";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection5(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false,
    },
  },
  components: { DocFields, MultiSelectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { docs, fetchWithDelay } = useDocIzOrigin(props.documentTypeId);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let itemValidationRule = {
      registrationNumber: helpers.withMessage(
        "Заполните или удалите неиспользуемое поле",
        (value) => {
          return value !== null && value["registrationNumber"] !== undefined;
        }
      ),
    };

    return {
      data,
      update,
      docs,
      fetchWithDelay,
      itemValidationRule,
    };
  },
};
</script>
